<template>
    <div class="row no-gutters h-100">
        <div v-show="!layout.closedSidebar" :class="menuColumnClasses">
            <layout-menu />
        </div>
        <div :class="mainColumnClasses">
            <slot name="navigation"></slot>
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: "layout-page-default",

        computed: {
            layout() {
                return {
                    closedSidebar: this.$theme.$store.getters['isMainMenuClosed'],
                    minimisedSidebar: this.$theme.$store.getters['isMainMenuMinimised']
                }
            },

            menuColumnClasses() {
                return {
                    'col-2': !this.layout.minimisedSidebar,
                    'col-1': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                    'p-fixed': true
                }
            },

            mainColumnClasses() {
                return {
                    'col-10': !this.layout.minimisedSidebar,
                    'offset-2': !this.layout.minimisedSidebar,
                    'col-11': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                    'offset-1': this.layout.minimisedSidebar && !this.layout.closedSidebar,
                    'col-12': this.layout.closedSidebar,
                    'offset-0': this.layout.closedSidebar
                }
            }
        }
    }
</script>

<style scoped>
    body > div#app > div.row > div.col-2,
    body > div#app > div.row > div.col-1,
    body > div#app > div.row > div.col-10,
    body > div#app > div.row > div.col-11,
    body > div#app > div.row > div.col-12 {
        padding-top: 61px;
    }

    .p-fixed {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
    }
</style>
